<template>
    <div></div>
</template>

<script>
    export default {
        beforeCreate(){
            this.$router.push('dashboard');
        }
    }
</script>
